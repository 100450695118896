import MenuSticky from '../js/MenuSticky'

export default {
  init() {
    console.log('home');
    // new MenuSticky();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
