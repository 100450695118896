import $ from 'jquery'

export default class MenuSticky {

  constructor() {        
    this._init();
  }

  _init() {

    let _this = this;
    var sticky = 0;
    var menu = document.querySelector(".section-hero .main-menu-wrapper");    

    if (menu) {
      window.addEventListener('scroll', myFunction);
      sticky = menu.offsetTop;
      //console.log('top: ' + sticky);
    }
    else {
      window.removeEventListener('scroll', myFunction);
    }

          
    

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      // console.log('Sticky: ' + sticky + ' Y: ' + window.pageYOffset + "Diff: " + (sticky - window.pageYOffset));
      if (window.pageYOffset >= (sticky - 0) ) {
        menu.classList.add("sticky")
        //console.log(' STICKY STICKY STICKY STICKY STICKY STICKY ')
      } else {
        menu.classList.remove("sticky");
      }

      if (window.pageYOffset >= (sticky + 130)) {
        menu.classList.add("bg")
      } else {
        menu.classList.remove("bg");
      }
    }

    $('.panquecas').on('click', function(e){
      
      $('.main-menu-wrapper').toggleClass('grow');

      if ($('.main-menu-wrapper').hasClass('grow')) {
        $('.main-menu-wrapper').animate({'height': '340px'},250, function(){
          $('.main-menu-wrapper .main-menu').addClass('show');
        });
      }
      else {        
        $('.main-menu-wrapper').animate({'height': '90px'},250, function() {
          $('.main-menu-wrapper .main-menu').removeClass('show');
        });
        
      }

      // $(".main-menu-wrapper").bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
      //   $('.main-menu-wrapper .main-menu').addClass('show');
      // });
    });
    

  }


  
}