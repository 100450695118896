import $ from 'jquery'
 // import Swiper JS
 //import Swiper, { Navigation, Pagination } from 'swiper';
 // configure Swiper to use modules
 //Swiper.use([Navigation, Pagination]);

 // import Swiper bundle with all modules installed
 import Swiper from 'swiper/bundle';

 // import Swiper styles
 import 'swiper/swiper-bundle.css';
 import imagesLoaded from 'imagesloaded'

export default class Modal {

  constructor() {        
    this._init();
  }

  _init() {  
    $('.category-teaser .img-wrapper').on('click', (e) => {
      const $teaser = $(e.currentTarget).parent(".category-teaser")
      const category = $teaser.attr('data-slug');
      const workId = $teaser.attr('data-work');
      const catLink = $teaser.attr('data-cat-link');
      this._getSliderData(category, workId, catLink);
    });

    $('.cats-page').on('click', '.work-teaser', (e) => {
      const category = $('.cat-item.selected').attr('data-cat');
      const workId = $(e.currentTarget).attr('data-id');
      
      this._getSliderData(category, workId, "");
    });

    $('.modal .close').on("click", () => {      
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      $('.modal').removeClass('show');
    });

    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

  }

  _getSliderData(category, workId, catLink) {
      let _this = this;
      // todo: maybe show model with loading here
      // get works html      

      let data = {
        'action': 'works_by_cat_slider',
        'category': category,
        'workId' : workId, 
        'nonce': $("[name='csrf-token']").attr("content"),
      };
      $.ajax({        
        url : window.ajaxurl,
        data : data,
        dataType: 'json',        
        type : 'POST',
        success : function( data ){
          if( data ) {
            $('.modal .modal-body').html(data.works_html);
            const link = document.querySelector('.view-all-link');
            link.classList.remove('show');
            if (catLink != "") {
              link.href = catLink;
              link.classList.add('show');
            }
            _this._showModel();
            _this._initSlider();
            const slideIndex = _this.getSlideIndexFromWorkId(workId); 
            _this.swiper.slideTo(slideIndex, 0, false)
            
            // todo: remove modal loading here         
            let elWrapper = document.querySelector('.modal-body');               
            imagesLoaded(elWrapper, function() {
              setTimeout(() => {
                $('.modal .loading').addClass('hide');
              $('.modal .modal-body').removeClass('hide');
              }, 500);
              
            });
            
          }
        },
      });
  }

  getSlideIndexFromWorkId(workId) {
    const item = document.querySelector('.swiper-slide[data-id="' + workId + '"]');
    const index = $( ".swiper-slide" ).index( item );    
    return index;
  }

  _initSlider() {    
    // initialize slider
    this.swiper = new Swiper('.works-slider', {
      // hashNavigation: true,
      // hashNavigation: {
      //   watchState: true,
      // },
      zoom: true,
      effect: 'fade',
      //autoHeight: true, //enable auto height
      slidesPerView: 1,
      // spaceBetween: 30,
      // centeredSlides: true,
      // Optional parameters
      direction: 'horizontal',
      loop: false,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });    
  }

  _showModel() {    
    $('.modal').addClass('show');
    $('.modal .loading').removeClass('hide');
    $('.modal .modal-body').addClass('hide');

    // Remove body scroll and fix for mobile
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  }

  
}