// import CatItems2 from '../js/CatItems2'
import MasonryItems from '../js/MasonryItems'
import CatsPage from '../js/CatsPage'

export default {
  init() {    
    // console.log('category')
    let masonryItems = new MasonryItems();   
    masonryItems.init();
    new CatsPage();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
