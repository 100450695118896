import $ from 'jquery'
//import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

export default class CatsPage {

  constructor() {            
    this._init();
  }

  _init() {

    let _this = this;    
    $('.cats-page .cat-item').on('click', function(e){

      let $catItem = $(this);
      const category = $(this).attr('data-cat');
      const catUrl = $(this).attr('data-url');
      const catTitle = $(this).attr('data-title');
      
      $('.cat-item').removeClass('selected'); // remove all selected
      $catItem.addClass('selected');      
      
      //$( ".works-wrapper" ).fadeOut('slow');
      $('.loading').removeClass("hide");
      $( ".works-wrapper" ).addClass('hide');

      
      
      // get works html

      let data = {
        'action': 'works_by_cat',
        'category': category,
        'nonce': $("[name='csrf-token']").attr("content"),
      };
      $.ajax({        
        url : window.ajaxurl,
        data : data,
        dataType: 'json',        
        type : 'POST',
        success : function( data ){
          if( data ) {                        
              $('.works-wrapper').html(data.works_html);
              let elWrapper = document.querySelector('.works-wrapper');

              imagesLoaded(elWrapper, function() {    
                                           
                setTimeout(() => {
                  
                  $('.loading').addClass("hide");
                  $( ".works-wrapper" ).removeClass('hide');
                }, 400);
                
                
                                             
              });                                                                 

              const nextURL = catUrl;
              const nextTitle = catTitle;
              const nextState = { oldUrl: location.url };

              

              // This will create a new entry in the browser's history, without reloading
              //window.history.pushState(nextState, nextTitle, nextURL);

              // This will replace the current entry in the browser's history, without reloading
              window.history.replaceState(nextState, nextTitle, nextURL);
            
            
            
            
          }
        },
      });
      

      
    });    


    // window.onpopstate = function(e){
    //   if(e.state){
    //     console.log('reloading..');
    //     console.log(e.state);
    //     location.reload();
    //   }
    // };

  }


  
}