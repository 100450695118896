import Modal from '../js/Modal'
import MenuSticky from '../js/MenuSticky'

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');

    new Modal();    
    new MenuSticky();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
