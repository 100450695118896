import $ from 'jquery'
//import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

export default class MasonryItems {

  constructor() {
  }

  init() {
    let elWrapper = document.querySelector('.works-wrapper');
    let masonryGrid;

    imagesLoaded(elWrapper, function() {
      // masonryGrid = new Masonry(elWrapper, {
      //   // options
      //   itemSelector: '.work-teaser',
      //   gutter: 30,      
      //   transitionDuration: 0 // no transitions
      // });
      $('.loading').addClass("hide");
      $( ".works-wrapper" ).removeClass('hide');
      //masonryGrid.layout();
    });    

  }


}